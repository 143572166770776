import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

import LinkButton from "./subcomponents/LinkButton";
import Loading from "./subcomponents/Loading";
import themeLinks from "../data/theme-menu";

//import "./ThemeEvent.css";
import Meta from "./subcomponents/Meta";
import Oops from "./subcomponents/Oops";
import InfoButton from "./subcomponents/InfoButton";
import parseBlog from "./subcomponents/ParseBlog";
import AdTheme from "./Ads/AdTheme";

export default function ThemeEvent () {

  const [events, setEvents] = useState();
  const {themeUrl} = useParams();
  const [isLoad, setIsLoad] = useState(null);

  //console.log("Start of load event url: ", eventUrl);
        
  const [columns, setColumns] = useState(Math.floor((window.innerWidth - 20)/120));
  const [isRewards, setIsRewards] = useState(false);
  const [visibleLevels, setVisibleLevels] = useState(calcLevels(false, columns));
  
  const [numBuildings, setNumbuildings] = useState(0);
  
  React.useEffect (() => {                  //monitors for resizes

    // console.log(window.innerWidth);
    const debouncedHandleResize = debounce(function handleResize() {
      const newColumns = Math.floor((window.innerWidth - 20)/120);
      setColumns(newColumns);
      setVisibleLevels(calcLevels(isRewards, newColumns));
      
    }, 200);

    function handlePortrait () {
      if (window.orientation === 0) {
        //console.log(window.innerWidth);
        const viewportmeta = document.querySelector('meta[name=viewport]');
        // viewportmeta.setAttribute('content', "width=device-width initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0");
        viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0, minimum-scale=1.0");
      };
    }
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('orientationchange', handlePortrait);
    
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
      window.removeEventListener('orientationchange', handlePortrait);
      const viewportmeta = document.querySelector('meta[name=viewport]');
      viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0"); 
    }
  });

  useEffect(()=>{                    // Grabs the envents
    axios.get('/api/event')
      .then(res => {
        // console.log("Events from database", res.data);
        setEvents(res.data);
      });
  },[]); 
  
  useEffect(()=>{
    axios.get('/api/calendar/current')
      .then(res => {
        // console.log("Current event search results: ", res.data);
        setIsLoad(res.data.link.split('/')[2]);
      });
  },[]);  
 
  function calcLevels (show, col){ 

    
    const all = [0,1,2,3,4,5];
    let levels = [];
    switch (col) {
      case 1 :
        show ? levels = [2] : levels = [1];
        break;
      case 2 :
        show ? levels = [2] : levels = [1,2];
        break;
      case 3 :
        show ? levels = [2] : levels = [1,2,3];
        break;
      case 4: 
        show ? levels = [2,3] : levels = [0,1,2,3];
        break;
      case 5: 
        show ? levels = [2,3] : levels = [0,1,2,3,4];
        break;
      case 6: 
        show ? levels = [1,2,3] : levels = all;
        break;
      case 7: 
        show ? levels = [1,2,3] : levels = all;
        break;  
      case 8:
        show ? levels = [1,2,3,4] : levels = all;
        break;
      case 9:
        show ? levels = [1,2,3,4] : levels = all;
        break;
      case 10: 
        show ? levels = [0,1,2,3,4] : levels = all;
        break;
      case 11: 
        show ? levels = [0,1,2,3,4] : levels = all;
        break;
      default:
        levels = all
        break;
    }
    return levels; 
  }

  //******** State Functions ********************* */
  function levelUp(){
    let newLevel = visibleLevels;
    if (visibleLevels[visibleLevels.length-1] < 5) {
      newLevel = [...visibleLevels, visibleLevels[visibleLevels.length-1] + 1];     // current array plus the next number
      newLevel.shift();                                                             // remove the first number
      setVisibleLevels(newLevel);
    }
  }

  function levelDown(){
    let newLevel = visibleLevels;
    if (visibleLevels[0] > 0) {
      newLevel = [visibleLevels[0] - 1, ...visibleLevels];                          // adds the lowest
      newLevel.pop();                                                               // removes the last item
      setVisibleLevels(newLevel);  
    }
  }

  function toggleRewards(){
    setVisibleLevels(calcLevels(!isRewards, columns));
    setIsRewards(!isRewards);
    
  }

  //******* Minor Components ******************************************************* */

  function HeaderRow(props) {
    const {rewards} = props
    return (
      <React.Fragment>
        {props.visLevels.map((level, i)=>{
          return (<React.Fragment key = {i}>
            {/* <th className = "left-border"><img className = "level-icon " src = {"/images/themes/star"+ (level+1) +".png"} alt={"level "+ (level+1)}></img></th> */}
            <th className = "left-border"><span className = 'theme-level-text'>{level + 1}</span><img className = "theme-level-star" src = {"/images/themes/star.png"} alt={"level "+ (level+1)}></img></th>
            {/* <th className = "left-border">{(level+1) + " star"}</th> */}
            {isRewards && <th>
              
              
              {Object.keys(rewards).map((key, i)=>{
                
                if (rewards[key][level] !==0) 
                  return <DataCell key = {i} pic = {key} amount = {rewards[key][level]}/>
                else return null
                
              })}


              {/* <DataCell pic = "rticket" amount = {props.tickets[level]}/>
              {props.animalChest[level] !== undefined && props.animalChest[level] !== 0 && <DataCell pic = "animal-chest-token" amount = {props.animalChest[level]}/>} */}
            </th>}
          </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }      

  function StageRow(props) {
    let {collect, reward} = props.stage;
   
    return (
      <tr>
        {props.visLevels.map((level, i)=>{                 //step through each level
          
          return(
            <React.Fragment key = {i}>

              {/* Return tasks */}
              <td className = "left-border">
                {Object.keys(collect).map((key, i) => {
                  
                  if (key === "rate") {
                    if (!numBuildings) {  // if there are 0 buildings
                      return <span key = {i}>{collect[key]+"/hr"}<br /></span>
                    } else {
                      let item = Object.keys(collect)[0];
                      return <span key = {i}>{Math.round(collect[item][level] / collect[key] / numBuildings)+" hrs"}<br /></span>
                    }
                  } else if (collect[key][level] !== 0) {
                    return <DataCell key = {i} pic = {key} amount = {collect[key][level]}/>
                  } else {
                    return null;
                  }  

                })}
              </td>
              {/* Return rewards */}        
              {isRewards && 
                <td>
                  {Object.keys(reward).map((key, i) => {
                    //console.log(key);
                    if (reward[key][level] !== 0) {
                      return <DataCell key = {i} pic = {key} amount = {reward[key][level]}/>
                    } else {
                      return null;
                    }  

                  })}
              </td>}
            </React.Fragment>
          );
        })}
      </tr>
    );
  }      

  function DataCell(props) {
    let {pic, amount} = props;
    if (amount === 999) {
      amount = "?";
    }
    return ( <span>{numberWithCommas(amount)}<img className = "task-icon" src = {"/images/themes/"+ pic +".png"} alt="Oops"></img><br /></span>);
  }

  function LevelButton (props){
    return <button className= {"button " + props.class} onClick = {props.onClick}>
      <img className = "button-icon" src={"/images/themes/" + props.pic + ".png"} alt= {props.alt} /> 
    </button>
  }

  function handleNumBuilding (event){
    //console.log(event.target.value);
    setNumbuildings(parseInt(event.target.value));
  }

  function DecoCard(props){
    let {number, deco} = props;
    let happiness, size;
    switch (number) {
      case ("5-6305"): {
        happiness = "55-115";
        size = "6x6";
        break;
      }
      case (20): {
        happiness = 22;
        size = "3x3"
        break;
      }
      case(50): {
        happiness = 47;
        size = "3x6"
        break;
      }
      case(100): {
        happiness = 99;
        size = "6x6"
        break;
      }
      default: {
        happiness = 0;
      }
    }
    return <div className = "deco-card">
      <p className = "theme-deco-ticket-text">{number}<img className = "task-icon" src = {"/images/themes/" + (number === "5-6305"? "r" : "y") + "ticket.png"} alt="Yellow tickets"></img>{number === "5-6305" && <InfoButton link = "/buildings/theme-upgradeable" />} </p>
      <p><img className = {"display-deco-" + (number === "5-6305" ? "red" : "yellow")} src = {"/images/themes/" + deco + ".png"} alt="Yellow tickets"></img></p>
      <p className = "theme-deco-size-text">{size}</p>
      <p className = "theme-deco-happy-text">+{happiness}<img className = "task-icon" src = {"/images/themes/happiness.png"} alt="Happiness"></img></p>
      
    </div>
  }

  function createMarkup(innerHtml) { return {__html: parseBlog(innerHtml)}; }

  // logic to load the correct event based on url or calendar if no url
  let event; 

  if (events === undefined) {   // data is not loaded yet.
    // console.log("Data is not loaded from database yet, put up loading screen");
    return <Loading text = "Loading events..." />
  } else {
    
    if (themeUrl !== undefined) {
      // set filter for url event
      event = events.filter(event => event.link === themeUrl)[0];
    } else {
      // url doesn't exist, so grab the current event
      if (isLoad === null) {
        // console.log("the current event is not loaded from the database yet")
        return <Loading text="Fetching the current theme event..." />
      } else {
        // console.log("Setting the current event", isLoad)
        event = events.filter(event => event.link === isLoad)[0];
      }
    }
    if (event === undefined) {
      //return <h2>Something went wrong.  Invalid url</h2>
      return <Oops text = "That event doesn't seem to exist...  I'll take you to the current event" link="/themes/"/>
      // return <Redirect to = {"/events/"} />
    }
  
  let tasks = {products: [], collect: [], ptask: [], ctask: []};
  // console.log(event.tasks)
  event.tasks.forEach((task, i) => {
    if (task.collect.rate === undefined) {
      Object.keys(task.collect).forEach((item)=> {
        !tasks.ptask.includes(i+1) && tasks.ptask.push(i+1)
        tasks.products.push(item) 
      })
        
      // Object.keys(task.collect)
    } else {
      tasks.ctask.push(i+1)
      tasks.collect.push(Object.keys(task.collect)[0])
    }
  })

  // console.log("Tasks", tasks)
 
  const first = (i) => {
    let written = "6th"
    switch (i) {
      case 1:
        written = "1st";
        break;
      case 2:
        written = "2nd";
        break;
      case 3:
        written = "3rd";
        break;  
      case 4:
        written = "4th";
        break;  
      case 5:
        written = "5th";
        break;
      default:
        break;
    }
    return written;
  }
    
  //************************* Master Function Return ******************************/
  
    
  Meta(`${event.name} - bfmhfansite.com`, `${event.name} - All of the tasks and rewards for Big Farm Mobile Harvest's weekly theme event.`); 
  return (
    <React.Fragment>
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {themeLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>

      <div className = "theme-blurb" style = {{padding: "20px"}}>
        <h2>{event.name}</h2>  
        <img className = "pimage pth plt" src={"/images/themes/" + event.link + "-header.png"} alt= {event.name} />
        {event.preamble && <div className = "t heme-strategy" dangerouslySetInnerHTML={createMarkup(event.preamble)}/>}
        
        <p>
          {event.name} is one of Big Farm Mobile Harvest’s weekly theme events.  
          A guide of how theme events work with general tips and strategy can be found <Link to = "/posts/theme-events">here</Link>.
          The current and next event can be found in the <Link to = "/" >coming up calendar</Link>.   
          The tasks, rewards, and decorations for {event.name} can be found below.  
        </p>
        <p>
          <img className = "pimage pum prt" src={"/images/themes/" + tasks.collect[1] + ".png"} alt= {event.name} />
          The {first(tasks.ctask[0])}, {first(tasks.ctask[1])}, and {first(tasks.ctask[2])} tasks are the <Link to = "/posts/theme-events">collection tasks</Link> and have a rate.
          More information on rate, how to calculate the time you need for an event, tips, and tricks, can be found in the <Link to = "/posts/rate">rate post</Link>.  
          The table can be customized to convert rate to theoretical hours for your farm under the table.
          Rose seeds will instantly give you these items at the quantity shown in the small table.   
        </p>
        <AdTheme />
        <h4>{event.name} Strategy</h4>
        {event.longStrat && <div className = "t heme-strategy" dangerouslySetInnerHTML={createMarkup(event.longStrat)}/>}
      </div>  
    
      <div className = "theme-event-master">
        <div className = "theme-header">
          <img className = "deco" src={"/images/themes/" + event.link + "-thumb.png"} alt = {event.name}></img>
          <h1>{event.name}</h1> 
          <div className = "table-levels">
            {!visibleLevels.includes(0) && <LevelButton pic="level-decrease" alt= "decrease level" onClick = {levelDown} />}  
            <LevelButton pic={isRewards ? "show-reward" : "hide-reward"} alt = "toggle rewards" onClick = {toggleRewards} />
            {!visibleLevels.includes(5) && <LevelButton pic="level-increase" alt= "increase level" onClick = {levelUp} />}
          </div>
        </div>
          
        <div style = {{"position": "relative"}}>
          <img className = "theme-header-img" src={"/images/themes/" + event.link + "-header.png"} alt= {event.name} />
          <p className = "watermark">bfmhfansite.com</p>
          {/* <table id= "theme-table" className = "theme-table"> */}
          <table className = "theme-table">
            
            <thead>
              <tr>
                <HeaderRow tickets = {event.tickets} animalChest = {event.animalChest} visLevels = {visibleLevels} rewards = {event.rewards}/>
              </tr>
            </thead>
            
            <tbody className = "notranslate">
              {event.tasks.map((stage, i)=>{
                return <StageRow key = {i} stage = {stage} visLevels = {visibleLevels}/>
              })} 
            </tbody>
          </table>
        </div>
        <p className = "footNote">Learn more at www.bfmhfansite.com</p>
        <div className = "customize-table"> 
          <h4>Customize the Table</h4>
          {/* <p>It's hard to compare numbers and rates, so pick a farm below that closely matches your farm to convert rate to hours for that many buildings.</p> */}
          <p>Numbers and rates means you need math to compare events.  Pick a farm below that is close to yours and I'll convert rate into farming hours.</p>
          <select value = {numBuildings} onChange = {handleNumBuilding}>
            <option value = "0">0 Buildings - Just rate</option>
            <option value = "15">15 Buildings - Level 20</option>
            <option value = "24">24 buildings - Level 40</option>
            <option value = "34">34 buildings - Level 70</option>
            <option value = "42">42 buildings - Level 100</option>
            <option value = "48">48 buildings - Level 150</option>
          </select><InfoButton link = "/posts/rate" />
        </div>
        
        
        {/* <h4>Rose Seeds</h4> */}
        <table id = "rate-table" className = "theme-table">
          
          <thead>
            <tr>
              {/* <th colSpan = "2">Rose Seeds</th> */}
              {/* <th>/hr</th> */}
              <th colSpan = "2" style = {{minWidth : "110px"}}><img className = "lrg-task-icon" src = {"/images/themes/roses.png"} alt="Oops"></img></th>
            </tr>
          </thead>
          <tbody className = "notranslate">
            {event.tasks.map((stage, i)=>{
              if (Object.keys(stage.collect)[1]=== "rate"){
                //console.log(Object.keys(stage.collect)[0], stage.collect.rate / 1.2 * 5);
                return( 
                  <tr key={i}>
                    <td><img className = "task-icon " src = {"/images/themes/" + Object.keys(stage.collect)[0] + ".png"} alt="Oops"></img></td>
                    {/* <td>{stage.collect.rate}</td> */}
                    <td>{stage.collect.rate === "xx" ? "xx" : Math.floor(stage.collect.rate / 1.2 * 5)}</td>
                  </tr>
                );
              } else {return null}  
            })}
          </tbody>
        </table>
        <br />
        
        {/* {event.strategy ? <div><h4>Strategy</h4><div className = "theme-strategy" dangerouslySetInnerHTML={createMarkup(event.strategy)}/></div>: null}     */}

        <h4>Decorations</h4>    
        <div className = "theme-decos">    
        
        <DecoCard Style = {{"float": "right"}} number = "5-6305" ticket = "rticket" deco = {event.link + "-thumb"}/>    
            <br />
             {event.decos.map((deco, i)=>{
              return <DecoCard key = {i} number = {deco} ticket = "yticket" deco = {event.link + "-" + deco}/>
            })}
        </div>
      </div>
    </React.Fragment>);
  }
}

function numberWithCommas(x) {
  
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}
