const dreamCalcData = [{
    ref: "farmhouse",
    active: false,
    userLevel: 0,
    allowZero: false,
    userSpread: 3,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
  {
    ref: "land",
    active: true,
    userLevel: 0,
    allowZero: false,
    userSpread: 3,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  }
  ,{
    ref: "windmill",
    active: true,
    userLevel: 0,
    allowZero: false,
    userSpread: 2,
    multiplier: 2,
    isMultiplier: 2,
    complete: true,
    cost: {}
  }
  ,{
    ref: "barn",
    active: true,
    userLevel: 0,
    allowZero: false,
    userSpread: 2,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
  {
    ref: "silo",
    active: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
 {
    ref: "field",
    active: true,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 9,
    complete: false,
    cost: {}
  },
  {
    ref: "house",
    active: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 9,
    complete: false,
    cost: {}
  },
  {
    ref: "apple-orchard",
    active: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
 
  {
    ref: "beehive",
    active: false,
    userLevel: -1,
    allowZero: true,
    userSpread: 2,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  }
  ]

export const bulldozerCalcData = [
{
  ref: "windmill",
  active: true,
  userLevel: 9,
  multiplier: 2,
  isMultiplier: 2
},
{
  ref: "silo",
  active: false,
  userLevel: 4,
  multiplier: 1,
  isMultiplier: false
},
{
  ref: "field",
  active: true,
  userLevel: 4,
  multiplier: 2,
  isMultiplier: 15
},
{
  ref: "house",
  active: false,
  userLevel: 4,
  multiplier: 2,
  isMultiplier: 15
  
},
{
  ref: "apple-orchard",
  active: false,
  userLevel: 0,
  multiplier: 2,
  isMultiplier: 6
 
},

{
  ref: "beehive",
  active: false,
  multiplier: 1,
  isMultiplier: false
  
}]

export const calc_date = "2025-01-20T14:06:18.053Z"

export const calc_crit_date = "2025-01-20T14:04:18.053Z"

export const update_reason = "Added houses, increased max number of Future levels to 5"

export default dreamCalcData;