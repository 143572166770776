import React, { useState, useEffect } from "react";
import axios from 'axios';

import Loading from "./subcomponents/Loading";

//import "./ThemeEvent.css";
import Meta from "./subcomponents/Meta";
import AdBlog from "./Ads/AdBlog";
// import parseBlog from "./subcomponents/ParseBlog";

export default function TreeOfLife() {

  const [data, setData] = useState();
  //console.log("Start of load event url: ", eventUrl);
        
  const [playerLevel, setPlayerLevel] = useState(localStorage.getItem("TreeOfLifeLevel") ? parseInt(localStorage.getItem("TreeOfLifeLevel")): 0);

  useEffect(()=>{                    // Grabs the envents
    axios.get('/api/tree')
      .then(res => {
        // console.log("Tree data from Database", res.data);
        setData(res.data[0]);
        // console.log(res.data[0])
      });
  },[]);  

  
  //******* Minor Components ******************************************************* */

  function handlePlayerLevel (event){
    // console.log("new index: ",event.target.value);
    setPlayerLevel(parseInt(event.target.value));
    localStorage.setItem("TreeOfLifeLevel", event.target.value)
  }

  // function createMarkup(innerHtml) { return {__html: parseBlog(innerHtml)}; }

  if (data === undefined) {   // data is not loaded yet.
    // console.log("Data is not loaded from database yet, put up loading screen");
    return <Loading text = "Loading table data..." />
  } else {
    
  //************************* Master Function Return ******************************/
  
    Meta(`Tree of Life - bfmhfansite.com`, `All of the tasks for the monthly Tree of Life event.`); 
    return (
      <React.Fragment>
        <div className = "tree-of-life-page blog-post">
          <h1>Tree of Life</h1> 
          <img className = "pimage psm prt" src = "/images/tree/tree-of-life-med.png" alt ="Tree of Life"></img>
          <p>The Tree of Life happens every 28 days (starts on Mondays at 08:30 UTC) during the middle of a Farm Contest season.  You can find the next event in the Coming Up section on the home page.  (If you don't see it there, it's because there are more than 12 events before the next Tree of Life.)</p>
          <p>The Tree of Life is a collection of 20 tasks spread across 4 areas of your farm.  The Tree represents this as 4 colored branches of flowers on the Tree, with each flower representing a task.  You must complete the 'lower' (starting) flowers to reveal the tasks of the next flowers (or see the table below).</p>
          <p>As you progress through the tasks, you earn rewards and tree token, which unlock more rewards across the bottom of Tree of Life interface.  Some tasks split into 2 flowers.  You must complete both flowers to move on to the next flower.</p>
          <p>The tasks and rewards are dependent on your player level when the event starts.  If you level up during an the event, your tasks and rewards do not change.</p>
          {Math.random() > .2 && <AdBlog />}
          <p>Every player does the same set of tasks, but the quantity of products in each task increases as your player level increases.  Players level 70 and below have 4 different or modified tasks because they don't have the available buildings.</p>
          <p><img className = "pimage pum plt" src = "/images/tree/tree-of-life.png" alt ="Tree of Life Token"></img>
            The 7 large rewards across the bottom on the interface are earned at 50, 150, 300, 500, 800, 1250, 1850 tree tokens.</p>
          <p>The list of tasks below will change based on the farm level you select.  I will keep building the table to include rewards and points as time allows.  I'm also still looking for 26 tasks.</p>
          <br></br>
          
          <div className = "tree-of-life-table">
            {/* {console.log("Player Level: ", playerLevel)} */}
            <p>Select your farm level to fill in the table: <select name = "treeLevel" value = {playerLevel} onChange = {handlePlayerLevel}>
              {data.playerLevels.map((display, i) => <option key = {i} value = {i}>{display}</option>)} 
            </select></p>
            {/* <h4>Rewards</h4> */}

            {data.branches.map((branch, i) => 
              <div key = {i}>
                <h4 style = {{color : branch.color}}>{branch.name}</h4>
                {/* {console.log(branch)} */}
                {branch.flowers.map((flower, i)=>
                <div key = {i}>
                  {/* {console.log("branch: ", branch, "flower: ", flower)} */}
                  <p style = {{color : branch.color}}>{flower.name +  " -> " + flower.rewards[1]}<img className = "branch-rewards" src = "/images/tree/tree-of-life.png" alt ="Tree of Life Token"></img></p>
                  {flower.tasks.map((task, i)=>{
                    let amount = task.data[playerLevel];
                    if(amount === 999) amount = "?";
                    // console.log(amount);
                    if (amount) {return <p key = {i}>{task.preamble}<span className = "notranslate">{numberWithCommas(amount)}</span>{task.postamble}</p>}
                    else return null;
                    }
                
                  )}
                </div> )} 
              </div>)}
          </div>
        </div>
  
        {/* <div className = "theme-event-master">
          <div className = "customize-table">  */}
      </React.Fragment>

    );
  }
}

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}



//   "html" : 
/* ;


<p>Interactive tasks lists will follow shortly.  For now:</p>

<h4 style = 'color : #FF9C0B'>Yellow Flowers:</h4>
<h4 style = 'color : #C14AE4'>Purple Flowers</h4>
<h4 style = 'color: #F74F80'>Pink Flowers</h4>
<h4 style = 'color: #225DC1'>Blue Flowers</h4>
*/
