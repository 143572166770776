import React,{useEffect} from "react";
// import { useLocation } from "react-router-dom";
import './Ad.css';

export default function AdTheme() {
    
    useEffect(()=>{
        const adScript = setTimeout(()=>{
          (window.adsbygoogle = window.adsbygoogle || []).push({})   
        }, 10)
        return () => {
            clearTimeout(adScript) 
        };
    },[])

    // const isSmall = window.innerWidth < 365

    return <ins className="adsbygoogle"
        style= {{display: "block"}}
        data-ad-client="ca-pub-6837707729833975"
        data-ad-slot="4554373007"
        data-ad-format="auto"   
        data-full-width-responsive="true">
    </ins>
}