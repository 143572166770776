import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NewsNotification (props) {
    let navigate = useNavigate();
    return <div id = "newsNotification" className = "news-notification-hidden" onClick = {()=> navigate("/news")}>
        {/* <Link to = "news" ></Link> */}
        <img className="news-notification-arrow" src = "/images/menu/link-arrow.png" alt = "link arrow" />
        <img className="news-notificaiton-thumb" src = "/images/menu/news.png" alt="news"/>
        <p className = "calendar-event-title">News Available</p>
        <p className = "calendar-event-date">Check it out</p>
      </div>
}

