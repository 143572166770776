import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InfoButton from './subcomponents/InfoButton';
import parseBlog from './subcomponents/ParseBlog';
import { Link } from 'react-router-dom';
 
export default function News (props) {

  const {archive} = props  
  const [news, setNews] = useState();
  const [notifications, setNotifications] = useState([]);

  useEffect (()=>{               // get news from database
		axios.get('/api/news/')
      .then(res => {
        setNews(archive ? res.data : res.data.filter(item => !item.archive));  
        // console.log("all news from database: ", res.data)
      });
    // eslint-disable-next-line  
  },[])

  useEffect(() => {              // manage notifications from local storage
    if (news !== undefined) {
      let expireDate = new Date();
      expireDate.setDate(expireDate.getDate()-15)               // set to time before removing news notifications.
      let currentNews = news.filter(_news => new Date(_news.date) > expireDate)
      
      let oldNoti = localStorage.getItem("new_noti_mng") ? JSON.parse(localStorage.getItem("new_noti_mng")): [];  //  if previous news mng exists, fetch it
      oldNoti = [...oldNoti.filter(item => new Date(item.date) > expireDate)]           // remove expired notificatons

      currentNews.forEach((item, i) => {
        let preExisting = oldNoti.filter(oldItem => oldItem._id === item._id);
        if (preExisting.length === 0) {
          oldNoti.push({_id: item._id, date: item.date, new: true});
        } else {
          if (item.date !== preExisting[0].date) {
            oldNoti = [...oldNoti.filter(removeItem => removeItem._id !== item._id)]
            oldNoti.push({_id: item._id, date: item.date, new: true});
          }
        }
      })

      setNotifications(oldNoti)
      localStorage.setItem("new_noti_mng", JSON.stringify(oldNoti));
    }
  }, [news])

  useEffect(() => {              // manage showing notification on home page
    if (notifications.lenght !== 0 && !archive) {
      let show = false;
      notifications.forEach(item => {
        if (item.new === true) {
          show = true
        }
      })
      if (show) {
        // console.log("Show the news alert")
        if (document.getElementById("newsNotification")) document.getElementById("newsNotification").className = "news-notification"  
      } else {
        if (document.getElementById("newsNotification")) document.getElementById("newsNotification").className = "news-notification-hidden"  
      }
    }
     // eslint-disable-next-line 
  },[notifications])

  const handleRead = (e, _id) => {
    e.preventDefault();
    if (notifications.filter(item => item._id === _id).length !== 0) {
      // console.log("New item acknowledged)")
      let newNoti = [...notifications]
      notifications.forEach((item, i) => {
        if (item._id === _id) {
          newNoti[i].new = false;
        }
      })

      setNotifications(newNoti)
      localStorage.setItem("new_noti_mng", JSON.stringify(newNoti));
      
    }
  }

  if (news === undefined) {
    return <React.Fragment>
      <br/>
      <h4>Fetching the news.  Give me a second or two...</h4>
    </React.Fragment> 
  } else {
    
    return <section className = "news">
      <h2><img className = "pum" src="/images/menu/news.png" alt = "News"></img> News</h2>
      {news.map((newsItem, i)=>{

        let noti = notifications.filter(flag => flag._id === newsItem._id)[0]
        if (noti === undefined) {noti = false}
        else {noti = noti.new}

        // console.log("Notification?", noti)
        return <NewsCard 
          flag = {noti}
          handleRead = {handleRead}
          id = {newsItem._id}
          key = {i}
          link = {newsItem.link}
          title = {newsItem.title}
          html = {newsItem.html}
          date = {newsItem.date}
          edit = {newsItem.edit}

        />
      })}
      {!archive &&<h4 className = "archived-news-link"><img className = "pum" src="/images/menu/news.png" alt = "News"></img> Archived news <Link to = "/news">here</Link></h4>}
    </section>
  }
}

function NewsCard (props) {
  const [isSummary, setIsSummary] = useState(true);
  function createMarkup(html) { return {__html: html}};
  
  const maxLength = 200;
  const newsLength = props.html.length;

  const edit = (props.edit && props.date !== props.edit) ? true : false
  
  return(<div id = {props.id} className = {props.flag ? "card-preview new-news" : "card-preview"} onClick = {props.flag ? (e) => props.handleRead(e, props.id): undefined}>
    {props.link !== undefined && <InfoButton link = {props.link}/>}
    <h3 className = "title">{props.title}</h3>
    <p className="news-date">{new Date(props.date).toDateString()} {edit && "(Updated)"}</p>
    <div className = "excerpt" dangerouslySetInnerHTML={createMarkup(parseBlog((isSummary &&(newsLength > maxLength))? props.html.slice(0,maxLength)+"... " : props.html))}/>
    {newsLength > maxLength && <Link to = "#" onClick = {() => setIsSummary(prev => !prev)}>{isSummary? "read more": "read less"}</Link>}
    {/* {edit && <p>Original Date: {new Date(props.edit).toDateString()}</p>} */}
    {props.flag && <span className = "news-howto">{window.innerWidth < 992 ? "Tap": "Click"} to clear</span>}
    <div style = {{"clear": "both"}} />
  </div>)
}
