import React,{useEffect} from "react";
// import { useLocation } from "react-router-dom";
import './Ad.css';

export default function AdMobile() {
    
    // const location = useLocation();
    // console.log("Location", location);

    useEffect(()=>{
        const adScript = setTimeout(()=>{
          (window.adsbygoogle = window.adsbygoogle || []).push({})   
        }, 10)
        return () => {
            clearTimeout(adScript); 
        };
    },[])

    // const isSmall = window.innerWidth < 365

    return <div style = {{maxWidth: "340px", margin: "0 auto"}}>
        
        <ins className = "adsbygoogle"
            style= {{display: "block"}}
            data-ad-client="ca-pub-6837707729833975"
            data-ad-slot="4210678524"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        {/* {isSmall ? 
            <ins className="adsbygoogle"
                style= {{display: "inline-block", width: "300px", height: "250px"}}
                data-ad-client="ca-pub-6837707729833975"
                data-ad-slot="3812843840">
            </ins>:
            <ins className="adsbygoogle"
                style= {{display: "inline-block", width: "336px", height: "288px"}}
                data-ad-client="ca-pub-6837707729833975"
                data-ad-slot="6760509184">
            </ins>
            } */}
    </div>
}